<script setup lang="ts">
import { useFirebase } from './lib/firebase'

const firebase = useFirebase()
if (typeof window !== "undefined") {
    (window as any).firebase = firebase
}

const menuDrawerModel = ref(false)

function closeMenuDrawer() {
  menuDrawerModel.value = false
}

function outForcs(event: Event) {
  if (event.target instanceof HTMLElement) {
    event.target.blur()
  }
}

const route = useRoute()

const title = computed(
  () => route.meta.title
      ? `${route.meta.title} | 玉川大学コスモス祭2024`
      : "玉川大学コスモス祭2024"
)

const description = computed(
  () => route.meta.description
      ? `${route.meta.description}`
      : "玉川大学コスモス祭2024公式サイト。今年は11月9日（土）と11月10日（日）に開催！"
)

useHead({ 
  title,
  meta: [
    { name: "description", content: description },
  ],
})

const config = useRuntimeConfig()
</script>

<style>
</style>

<template>
  <NuxtRouteAnnouncer />
  <header class="
    drawer
    drawer-end
    shadow-lg
  shadow-gray-500/20
  " style="
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
  "> 
    <input v-model="menuDrawerModel" id="menu-drawer" type="checkbox" class="drawer-toggle" />
    <div class="
      drawer-content
      grid-cols-[1fr_52px]
      lg:grid-cols-[calc(64px+460px)_1fr_52px]
    " style="
      display: grid;
      justify-content: center;
      align-items: center;
      height: 64px;
      z-index: 20;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(20px);
    ">
      <NuxtLink to="/" class="
        justify-center
        md:justify-start
      " style="
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 8px;
        padding-right: 4px;
      " @click="() => {
        if (route.path === '/') {
          reloadNuxtApp({
            path: config.app.baseURL,
            force: true,
          })
        }
      }">
        <img
          src="/favicon.png"
          class="hidden md:block"
          style="
            width: 52px;
            margin-right: 8px;
            max-height: 56px;
          "
        />
        <p class=" font-bold text-gray-600">
          <span class="text-sm md:text-4xl leading-none">
            玉川大学 
          </span>
          <br class="md:hidden" />
          <span class="text-3xl md:text-4xl">
            コスモス祭 2024
          </span>
        </p>
      </NuxtLink>
      <nav class="
        hidden
        lg:grid
      " style="
        justify-content: end;
        align-items: center;
      ">
        <ul class="menu menu-lg menu-horizontal p-0">
          <li>
            <NuxtLink class="px-2" to="/map" @click="outForcs">
              キャンパスマップ
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="px-2" to="/timetable" @click="outForcs">
              タイムテーブル
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="px-2" to="/event" @click="outForcs">
              企画・イベント
            </NuxtLink>
          </li>
          <li class="hidden xl:flex">
            <NuxtLink class="px-2" to="/booth" @click="outForcs">
              展示・模擬店
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <label for="menu-drawer" class="
        drawer-button 
        btn
        btn-ghost
        hover:btn-active
      " style="
        width: 48px; 
        height: 48px;
        padding: 0;
      " >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z"/>
        </svg>
      </label>
    </div>
    <div class="drawer-side" style="z-index: 20;">
      <label for="menu-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
      <nav class="grid grid-cols-[6px_1fr] content-start min-h-full p-4 w-80 bg-base-200 text-base-content">
        <ul class="grid grid-cols-2 content-start gap-3 mb-4 px-0 col-span-2">
          <li>
            <NuxtLink class="
              btn
              btn-outline 
              grid
              place-content-center
              h-full
              py-1
              text-xl
              text-center
              text-green-700
              hover:text-green-700
              hover:bg-stone-200
              hover:shadow-md
            " to="/map" @click="closeMenuDrawer">
              キャンパス<br />マップ
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="
              btn
              btn-outline 
              grid
              place-content-center
              h-full
              py-1
              text-xl
              text-center
              text-orange-500
              hover:text-orange-500
              hover:bg-stone-200
              hover:shadow-md
            " to="/timetable" @click="closeMenuDrawer">
              タイム<br />テーブル
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="
              btn
              btn-outline 
              grid
              place-content-center
              h-full
              py-3
              px-0
              text-lg
              text-center
              text-gray-700
              hover:text-gray-700
              hover:bg-stone-200
              hover:shadow-md
            " to="/event" @click="closeMenuDrawer">
              企画・イベント
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="
              btn
              btn-outline 
              grid
              place-content-center
              h-full
              py-3
              px-0
              text-lg
              text-center
              text-gray-700
              hover:text-gray-700
              hover:bg-stone-200
              hover:shadow-md
            " to="/booth" @click="closeMenuDrawer">
              展示・模擬店
            </NuxtLink>
          </li>
          <li class="col-span-2">
            <NuxtLink class="
              btn
              btn-outline 
              grid
              place-content-center
              h-full
              py-3
              px-0
              text-xl
              text-center
              text-gray-700
              hover:text-gray-700
              hover:bg-stone-200
              hover:shadow-md
            " to="/best-booth-award" @click="closeMenuDrawer">
              模擬店大賞
            </NuxtLink>
          </li>
        </ul>
        <div class="bg-gray-400 rounded" />
        <ul class="menu menu-lg p-0 ml-2 [&_li>*]:rounded-none [&_li>*]:p-2 ">
          <li class="">
            <NuxtLink class="" to="/" @click="closeMenuDrawer">
              ホーム
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/booth/orgs/dantai" @click="closeMenuDrawer">
              大学関連団体
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="/sponsor" @click="closeMenuDrawer">
              協賛企業
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <main>
    <NuxtPage />
  </main>
  
  <footer color="grey-lighten-3" style="
    width: 100%;
    margin-top: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    background: whitesmoke;
  ">
    <div class="prose" style="
      max-width: 900px;
      width: 100%;
      padding: 16px 8px;
    ">
      <h3 style="font-size: 1.6em; margin-bottom: 8px;">
        <span style="display: inline-block; margin-right: 8px;">玉川大学コスモス祭2024</span>
        <span style="display: inline-block">本部実行委員会</span>
      </h3>
      <p style="font-size: 1.1em; margin-bottom: 16px;">
        Email:
        <a href="mailto:cosmos.tamagawa.2023@gmail.com" style="margin-bottom: 4px; word-wrap: break-word;">
          cosmos.tamagawa.2024@gmail.com
        </a>
        <br />
      </p>
      <hr style="margin-top: 0; margin-bottom: 8px; border-color: dimgray;" />
      <p style="margin-bottom: 8px;">
        本サイトは利用者のアクセスを解析するためにGoogle Analyticsを利用しています。 本サイトをご利用になる場合、GoogleにアクセスしたページのURLやIPアドレスなどの情報を送信します。
        データ収集のために、Googleはcookieを設定したり既存のcookieを読み取ったりする場合があります。
      </p>
      <p>
        © 2024 玉川大学コスモス祭2024 本部実行委員会 All rights reserved.
      </p>
    </div>
  </footer>
</template>
