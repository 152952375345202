import { default as index5eLfdELeuBMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/admin/index.vue?macro=true";
import { default as confirmyKcPGFIobwMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/confirm.vue?macro=true";
import { default as _91area_9351tFSYdmvvMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/[area].vue?macro=true";
import { default as indexL87BDeNxRmMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/index.vue?macro=true";
import { default as successUsJjn3aqNGMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/success.vue?macro=true";
import { default as indexHpXdHHD7eHMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/index.vue?macro=true";
import { default as _91area_93XyO1z2b6U0Meta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/[area].vue?macro=true";
import { default as generatepss7PO7jgNMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/generate.vue?macro=true";
import { default as indexcMIS2VbIBNMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/index.vue?macro=true";
import { default as indexGQeySC11mKMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/index.vue?macro=true";
import { default as buncseGxbsxzEMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/bun.vue?macro=true";
import { default as dantai5I4GUXbuBMMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/dantai.vue?macro=true";
import { default as geijutsuw8EzU9sKXMMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/geijutsu.vue?macro=true";
import { default as kagaiXg8wJq3gdFMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kagai.vue?macro=true";
import { default as kankouNz3k4vH4n1Meta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kankou.vue?macro=true";
import { default as keieiEMjSsPcMabMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/keiei.vue?macro=true";
import { default as kenkyujo2zWv3vpNQyMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kenkyujo.vue?macro=true";
import { default as koubzMPxF6sMeMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kou.vue?macro=true";
import { default as kyouikujNDo9fNWeHMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kyouiku.vue?macro=true";
import { default as lbartshW7lgshBgLMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/lbarts.vue?macro=true";
import { default as nouKBWD6Xh2cxMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/nou.vue?macro=true";
import { default as _91area_93Jwe3Slnk4QMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/stall/[area].vue?macro=true";
import { default as eventpN6eEtVdNmMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/event.vue?macro=true";
import { default as indexW2I3xiOTtqMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/index.vue?macro=true";
import { default as mapFoGuGbVhRxMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/map.vue?macro=true";
import { default as sponsorL83Zx3xHQJMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/sponsor.vue?macro=true";
import { default as timetableKHpebQ4WXpMeta } from "/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/timetable.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: index5eLfdELeuBMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/admin/index.vue")
  },
  {
    name: "best-booth-award-confirm",
    path: "/best-booth-award/confirm",
    meta: confirmyKcPGFIobwMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/confirm.vue")
  },
  {
    name: "best-booth-award-form-area",
    path: "/best-booth-award/form/:area()",
    meta: _91area_9351tFSYdmvvMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/[area].vue")
  },
  {
    name: "best-booth-award-form",
    path: "/best-booth-award/form",
    meta: indexL87BDeNxRmMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/index.vue")
  },
  {
    name: "best-booth-award-form-success",
    path: "/best-booth-award/form/success",
    meta: successUsJjn3aqNGMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/form/success.vue")
  },
  {
    name: "best-booth-award",
    path: "/best-booth-award",
    meta: indexHpXdHHD7eHMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/index.vue")
  },
  {
    name: "best-booth-award-qr-area",
    path: "/best-booth-award/qr/:area()",
    meta: _91area_93XyO1z2b6U0Meta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/[area].vue")
  },
  {
    name: "best-booth-award-qr-generate",
    path: "/best-booth-award/qr/generate",
    meta: generatepss7PO7jgNMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/generate.vue")
  },
  {
    name: "best-booth-award-qr",
    path: "/best-booth-award/qr",
    meta: indexcMIS2VbIBNMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/best-booth-award/qr/index.vue")
  },
  {
    name: "booth",
    path: "/booth",
    meta: indexGQeySC11mKMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/index.vue")
  },
  {
    name: "booth-orgs-bun",
    path: "/booth/orgs/bun",
    meta: buncseGxbsxzEMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/bun.vue")
  },
  {
    name: "booth-orgs-dantai",
    path: "/booth/orgs/dantai",
    meta: dantai5I4GUXbuBMMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/dantai.vue")
  },
  {
    name: "booth-orgs-geijutsu",
    path: "/booth/orgs/geijutsu",
    meta: geijutsuw8EzU9sKXMMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/geijutsu.vue")
  },
  {
    name: "booth-orgs-kagai",
    path: "/booth/orgs/kagai",
    meta: kagaiXg8wJq3gdFMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kagai.vue")
  },
  {
    name: "booth-orgs-kankou",
    path: "/booth/orgs/kankou",
    meta: kankouNz3k4vH4n1Meta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kankou.vue")
  },
  {
    name: "booth-orgs-keiei",
    path: "/booth/orgs/keiei",
    meta: keieiEMjSsPcMabMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/keiei.vue")
  },
  {
    name: "booth-orgs-kenkyujo",
    path: "/booth/orgs/kenkyujo",
    meta: kenkyujo2zWv3vpNQyMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kenkyujo.vue")
  },
  {
    name: "booth-orgs-kou",
    path: "/booth/orgs/kou",
    meta: koubzMPxF6sMeMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kou.vue")
  },
  {
    name: "booth-orgs-kyouiku",
    path: "/booth/orgs/kyouiku",
    meta: kyouikujNDo9fNWeHMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/kyouiku.vue")
  },
  {
    name: "booth-orgs-lbarts",
    path: "/booth/orgs/lbarts",
    meta: lbartshW7lgshBgLMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/lbarts.vue")
  },
  {
    name: "booth-orgs-nou",
    path: "/booth/orgs/nou",
    meta: nouKBWD6Xh2cxMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/orgs/nou.vue")
  },
  {
    name: "booth-stall-area",
    path: "/booth/stall/:area()",
    meta: _91area_93Jwe3Slnk4QMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/booth/stall/[area].vue")
  },
  {
    name: "event",
    path: "/event",
    meta: eventpN6eEtVdNmMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/event.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexW2I3xiOTtqMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/index.vue")
  },
  {
    name: "map",
    path: "/map",
    meta: mapFoGuGbVhRxMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/map.vue")
  },
  {
    name: "sponsor",
    path: "/sponsor",
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/sponsor.vue")
  },
  {
    name: "timetable",
    path: "/timetable",
    meta: timetableKHpebQ4WXpMeta || {},
    component: () => import("/home/runner/work/cosmosfair-website-2024/cosmosfair-website-2024/cosmosfair-website-2024/pages/timetable.vue")
  }
]