import { initializeApp, type FirebaseApp, type FirebaseOptions } from "firebase/app"
import { getAnalytics, logEvent, type Analytics, type AnalyticsCallOptions, type CustomEventName } from "firebase/analytics"
import { getFirestore } from "firebase/firestore";

let app: FirebaseApp | undefined = undefined;
let analytics: Analytics | undefined = undefined;

export function useFirebase() {
  const nuxtRuntimeConfig = useRuntimeConfig()

  app = initializeApp({
    apiKey: nuxtRuntimeConfig.public.firebaseApiKey,
    authDomain: nuxtRuntimeConfig.public.firebaseAuthDomain,
    projectId: nuxtRuntimeConfig.public.firebaseProjectId,
    storageBucket: nuxtRuntimeConfig.public.firebaseStorageBucket,
    messagingSenderId: nuxtRuntimeConfig.public.firebaseMessagingSenderId,
    appId: nuxtRuntimeConfig.public.firebaseAppId,
    measurementId: nuxtRuntimeConfig.public.firebaseMeasurementId,
  })

  if (["", "production"].includes(nuxtRuntimeConfig.public.debugMode)) {
    if (typeof window !== "undefined") {
      analytics = getAnalytics(app)
    }
  }

  return { app, analytics }
}

export function useLogEvent() {
  const fnc = <T extends string>(
    eventName: CustomEventName<T>,
    eventParams?: {
      [key: string]: any;
    },
    options?: AnalyticsCallOptions
  ) => {
    if (analytics) {
      logEvent(analytics, eventName, eventParams, options)
    }
  }

  return fnc
}

export function useFirestore() {
  const firebase = useFirebase()
  return getFirestore(firebase.app)
}