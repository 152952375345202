import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig> {
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.path != from.path) {
      // ページ遷移でハッシュ値がある場合
      return {
        el: to.hash,
        top: 64,
      }
    } else if (to.hash && from.hash && to.hash !== from.hash && to.path == from.path) {
      // ページ内でのハッシュ値の変更（追加・削除は含まない）の場合
      return {
        el: to.hash,
        top: 64,
        behavior: "smooth",
      }
    } else if (to.path == from.path) {
      // ページ内での何らかの変更の場合
      return undefined
    } else {
      return { top: 0, left: 0 }
    }
  }
}